import {Component, Injector, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ControlValueAccessorConnector} from '../controlvalue-accessor.connector';

@Component({
  selector: 'app-dc-date-input',
  templateUrl: './dc-date-input.component.html',
  styleUrls: ['./dc-date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DcDateInputComponent,
      multi: true,
    },
  ],
})
export class DcDateInputComponent extends ControlValueAccessorConnector {
  @Input() id: string;
  @Input() label: string;
  @Input() readonly: boolean;

  constructor(injector: Injector) {
    super(injector);
  }

  canClickDateButton(dateObjRef) {
    dateObjRef.toggle();
  }
}
