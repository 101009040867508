<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{ title }}</h5>
  <button
    (click)="modal.dismiss('Close')"
    *ngIf="close"
    aria-label="Close"
    class="close"
    type="button"
  >
    Close
  </button>
</div>
<div [innerHtml]="body" class="modal-body" ngbAutofocus></div>
<div class="modal-footer justify-content-between">
  <div>
    <button
      (click)="onAction({ action: 1 })"
      *ngIf="action"
      class="btn btn-link btn-sm ps-0 me-3"
      type="button"
    >
      {{ action }}
    </button>
  </div>
  <div>
    <button
      (click)="onAction({ negative: 1 })"
      *ngIf="negative"
      class="btn btn-secondary btn-sm me-3"
      type="button"
    >
      {{ negative }}
    </button>
    <button
      (click)="onAction({ positive: 1 })"
      *ngIf="positive"
      class="btn btn-primary btn-sm"
      type="button"
    >
      {{ positive }}
    </button>
  </div>
</div>
