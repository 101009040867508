<ng-container *ngIf="authenticator.route !== 'authenticated'">
  <div class="loginWrap">
    <div class="bgImg"></div>
    <div class="logoSpacer">
      <div class="loginLogoContainer">
        <div class="loginLogo">
          <img
            src="inspire-white-magenta.svg"
            alt="Inspire Logo"
            height="67"
            width="240"
          />
        </div>

        <amplify-authenticator hideSignUp="true">
          <ng-template amplifySlot="sign-in-header">
            <h3
              class="amplify-heading logIn"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Log in
            </h3>
          </ng-template>
          <ng-template amplifySlot="setup-totp-header">
            <h3
              class="amplify-heading totp"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Setup Authentication
            </h3>
            <p>Please scan the QR code with your authenticator app</p>
          </ng-template>
          <ng-template amplifySlot="confirm-sign-in-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Confirm TOTP Code
            </h3>
          </ng-template>
          <ng-template amplifySlot="confirm-sign-up-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Please set a new password
            </h3>
          </ng-template>
          <ng-template amplifySlot="sign-up-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Please set a new password
            </h3>
          </ng-template>
          <ng-template amplifySlot="reset-password-header">
            <h3
              class="amplify-heading forgotPwd"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Request password reset
            </h3>
            <p>
              Please enter your username and click Send Code to reset your
              password
            </p>
          </ng-template>
          <ng-template amplifySlot="confirm-reset-password-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Enter Information:
            </h3>
          </ng-template>
        </amplify-authenticator>
        <div class="footer">
          <p>
            Need help?<br />
            <a
              href="mailto:inspire@deltacapita.com?subject=Website%20Support%20Query"
              target="_blank"
              class="contact-team"
              >Get in touch</a
            >
            with our team.
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="authenticator?.route === 'authenticated'">
  <app-dc-navbar
    (signOutEmitter)="authenticator.signOut()"
    [loggedInAsDistributor]="loggedInAsDistributor"
    [user]="authenticator.user.username"
    [email]="authenticator.user.attributes.email"
  >
  </app-dc-navbar>

  <ng-container>
    <app-dc-alert></app-dc-alert>
    <router-outlet></router-outlet>
  </ng-container>

  <div class="app-version">
    <div class="environment">
      {{ environment }}
    </div>
    Build Number: {{ appBuildNumber }}<br />
    Build Date: {{ appBuildDateString }}<br />
    Version: {{ appVersion }}<br />
  </div>
</ng-container>
