import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dc-table-search',
  template: `
    <div class="search-box">
      <button
        title="Click to {{ searchOpen ? 'clear' : 'open' }} search"
        class="btn-search"
        (click)="
          searchOpen = !searchOpen; searchText.value = ''; updateFilter($event); !searchOpen ? '' : searchText.focus()
        "
        [class.open]="searchOpen"
      ></button>
      <input
        type="text"
        #searchText
        (keyup)="updateFilter($event)"
        class="input-search"
        placeholder="Type to Search..."
        [class.open]="searchOpen"
      />
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .search-box {
        width: fit-content;
        height: fit-content;
        position: relative;
      }

      .input-search {
        height: 40px;
        width: 40px;
        border-style: none;
        padding: 10px;
        font-size: var(--text-large);
        outline: none;
        transition: all 0.5s ease-in-out;
        padding-right: 40px;
        color: #fff;
      }

      .input-search::placeholder {
        font-size: var(--text-large);
        font-weight: 100;
      }

      .btn-search {
        width: 40px;
        height: 40px;
        border-style: none;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 0px;
        color: #ffffff;
        background-color: transparent;
        pointer-events: painted;
        background-image: url(/assets/search-icon-pink.svg);
        background-position: center center;
        background-repeat: no-repeat;
      }

      .btn-search.open ~ .input-search {
        width: 260px;
        border-radius: 0px;
        background-color: transparent;
        transition: all 300ms linear;
      }

      .input-search.open {
        width: 260px;
        border-radius: 0px;
        background-color: transparent;
        transition: all 300ms linear;
      }
    `,
  ],
})
export class DcTableSearchComponent implements OnInit {
  @Output() searchValue: EventEmitter<any> = new EventEmitter();
  searchOpen;
  constructor() {}

  ngOnInit(): void {}

  updateFilter(e) {
    this.searchValue.emit(e);
  }
}
