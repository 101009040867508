import {Component, EventEmitter, forwardRef, Injector, Input, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ControlValueAccessorConnector} from '../controlvalue-accessor.connector';

@Component({
  selector: 'app-dc-radio',
  templateUrl: './dc-radio.component.html',
  styleUrls: ['./dc-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DcRadioComponent),
      multi: true,
    },
  ],
})
export class DcRadioComponent extends ControlValueAccessorConnector {
  @Input() name: string;
  @Input() id: string;
  @Input() label: string;
  @Output() changed = new EventEmitter();

  constructor(injector: Injector) {
    super(injector);
  }
}
