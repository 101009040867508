import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dc-date-input-range',
  templateUrl: './dc-date-input-range.component.html',
  styleUrls: ['./dc-date-input-range.component.scss'],
})
export class DcDateInputRangeComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() readonly: boolean;
  @Input() placeholder: string;
  @Input() darkMode: boolean;

  @Output() dateRangeOut = new EventEmitter();

  @ViewChild('d') input: NgbInputDatepicker;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;
  hoveredDate: NgbDate;
  fromDate;
  toDate;
  showDateClear = false;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.dateRangeOut.emit({ from: this.fromDate, to: this.toDate });
    this.showDateClear = true;
    let parsed;
    if (this.fromDate != null && this.toDate == null) {
      parsed = `${this.fromDate?.day}/${this.fromDate?.month}/${this.fromDate?.year} -`;
    }
    if (this.fromDate != null && this.toDate != null) {
      parsed = `${this.fromDate?.day}/${this.fromDate?.month}/${this.fromDate?.year} - ${this.toDate?.day}/${this.toDate?.month}/${this.toDate?.year}`;
    }

    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
  }

  canClickDateButton(dateObjRef) {
    dateObjRef.toggle();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  clearDate() {
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', '');
    this.showDateClear = false;
    this.fromDate = null;
    this.toDate = null;
    this.dateRangeOut.emit(null);
  }
}
