<label *ngIf="label">{{ label }}</label>
<div class="position-relative" style="height: inherit">
  <textarea
    [disabled]="disabled"
    [formControl]="control"
    [readonly]="readonly"
    class="form-control {{ class }}"
    rows="4"
  ></textarea>
  <app-dc-control-validation
    *ngIf="control.touched"
    [status]="control.invalid ? 'invalid' : 'valid'"
    class="{{
      control.hasError('required') && control.touched ? 'required' : ''
    }} "
    body="{{ control.hasError('required') ? 'Required field' : '' }}"
  ></app-dc-control-validation>
</div>
