import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { first } from 'rxjs/operators';
import { MonacoEditorService } from './monaco-editor.service';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
declare let monaco: any;
@Component({
  selector: 'app-dc-monaco-standalone',
  templateUrl: './dc-monaco-standalone.component.html',
  styleUrls: ['./dc-monaco-standalone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DcMonacoStandaloneComponent),
      multi: true,
    },
  ],
})
export class DcMonacoStandaloneComponent
  implements ControlValueAccessor, AfterViewInit, OnChanges, OnDestroy {
  @HostListener('control', ['$event']) onNgModelChange(value) {}
  @Input() value: string;
  public _editor: any;
  editorOptions = {
    value: 'tttt',
    theme: 'vs-dark',
    language: 'json',
    readOnly: false,
    autoIndent: true,
    formatOnPaste: true,
    formatOnType: true,
    automaticLayout: true,
  };
  @ViewChild('editorContainer', { static: true }) _editorContainer: ElementRef;
  @Input() model: any;
  @Input() language: string;
  @Input() tidyDocument: boolean;
  @Input() readOnly: boolean;
  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @Output() monacoInit = new EventEmitter();
  private _value: string = '';
  propagateChange = (_: any) => {};
  onTouched = () => {};
  disabled: boolean;
  constructor(
    private monacoEditorService: MonacoEditorService,
    private injector: Injector,
    private zone: NgZone
  ) {}

  get control() {
    return (
      this.formControl ||
      this.controlContainer.control.get(this.formControlName)
    );
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.editorOptions = {
      value: this.model,
      theme: 'vs-dark',
      language: this.language ? this.language : 'json',
      readOnly: this.readOnly ? true : false,
      autoIndent: true,
      formatOnPaste: true,
      formatOnType: true,
      automaticLayout: true,
    };
    if (this._editor) {
      this._editor.dispose();
      this._editor = undefined;
      this.initMonaco();
    }
  }

  writeValue(obj: any): void {
    this._value = obj || '';
    setTimeout(() => {
      if (this._editor) {
        this._editor.setValue(this._value);
        //this.tidy();
      }
    });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private initMonaco(): void {
    if (this.model) {
      this.editorOptions.value = this.model;
    }

    if (!this.monacoEditorService.loaded) {
      this.monacoEditorService.loadingFinished.pipe(first()).subscribe(() => {
        this.initMonaco();
      });
      return;
    }

    this._editor = monaco.editor.create(
      this._editorContainer.nativeElement,
      this.editorOptions
    );

    this.monacoInit.emit(this._editor);

    this.editorOptions.value = this._value;
    this._editor.onDidChangeModelContent((e: any) => {
      const value = this._editor.getValue();
      this.zone.run(() => {
        this.propagateChange(value);
        this._value = value;
      });
    });
  }

  ngAfterViewInit(): void {
    this.editorOptions = {
      value: this.model,
      theme: 'vs-dark',
      language: this.language ? this.language : 'json',
      readOnly: this.readOnly ? true : false,
      autoIndent: true,
      formatOnPaste: true,
      formatOnType: true,
      automaticLayout: true,
    };
    this.initMonaco();
  }

  tidy() {
    this._editor.getAction('editor.action.formatDocument').run();
  }

  ngOnDestroy() {
    if (this._editor) {
      this._editor.dispose();
      this._editor = undefined;
    }
  }
}
