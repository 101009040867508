import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dc-progress-bar',
  templateUrl: './dc-progress-bar.component.html',
  styleUrls: ['./dc-progress-bar.component.scss'],
})
export class DcProgressBarComponent implements OnInit {
  @Input() progressValue: number;
  @Input() progressLabel?: string;

  constructor() {
  }

  ngOnInit(): void {
  }
}
