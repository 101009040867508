import { Component, Injector, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../controlvalue-accessor.connector';
@Component({
  selector: 'app-dc-add-tags',
  templateUrl: './dc-add-tags.component.html',
  styleUrls: ['./dc-add-tags.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DcAddTagsComponent,
      multi: true,
    },
  ],
})
export class DcAddTagsComponent extends ControlValueAccessorConnector {
  @Input() label: string;
  @Input() labelId: string;
  @Input() items: any[];
  @Input() loading: boolean;
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {}

  addTagFn(name) {
    return name;
  }
}
