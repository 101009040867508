import { HttpClient } from '@angular/common/http';
import { Injectable, Output } from '@angular/core';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { BehaviorSubject, merge, Observable, of, Subject, timer } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
} from 'rxjs/operators';
// TODO check this for new environment (i.e. will this work in prod)
import { HOSTNAME } from '../../shared/util/hostname';
import { AuthServer } from '../model/settings-responses';
import { AuthSettingsService } from './auth-settings.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Hub } from 'aws-amplify';

const deepEqual = require('deep-equal');

export interface AuthErrorResponse {
  code: string;
  name: string;
  message: string;
}

export type AuthInfoResponse = string;

export interface IDToken {
  payload: {
    token_use: 'id';
    name: string;
    'cognito:username': string;
    email: string;
    email_verified: true;
    'cognito:groups'?: string[];
    iss: string;
    sub: string;
    aud: string;
    permissions: string;
    auth_time: number;
    exp: number;
    iat: number;
  };
  jwtToken: string;
}

export interface AccessToken {
  payload: {
    token_use: 'access';
    username: string;
    scope: string;
    'cognito:groups'?: string[];
    client_id: string;
    iss: string;
    sub: string;
    device_key: string;
    jti: string;
    auth_time: number;
    exp: number;
    iat: number;
  };
  jwtToken: string;
}

export interface UserSession {
  idToken: IDToken;
  accessToken: AccessToken;
  environment: string;

  isValid(): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // We need to store some static state which is used during the application load so that child routes are not prematurely cancelled by the guards
  private static authServerSubject = new BehaviorSubject<AuthServer>(undefined);
  private static authServers: AuthServer[] = [];
  private static accessibleAccounts: string[] = [];
  private static administeredAccounts: string[] = [];
  private static refreshSessionTrigger = new Subject<void>();
  private static userSessionRefreshTimer = timer(0, 1000);
  private static globalUserSession = merge(
    AuthService.userSessionRefreshTimer,
    AuthService.refreshSessionTrigger
  ).pipe(
    switchMap(() => {
      return Auth.currentSession()
        .then((userSession) => {
          if (userSession.isValid()) {
            return userSession;
          } else {
            return undefined;
          }
        })
        .catch((err) => {
          return undefined;
        });
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1)
  );
  hostname = HOSTNAME;
  apiRoot = 'https://api.' + this.hostname + '/v1';
  @Output() onAuthError = new BehaviorSubject<AuthErrorResponse>(undefined);
  @Output() onAuthInfo = new BehaviorSubject<AuthInfoResponse>(undefined);
  @Output() onLoginRequired = new BehaviorSubject<boolean>(false);
  @Output() onNewPasswordRequired = new BehaviorSubject<boolean>(false);
  @Output() onForgotPassword = new BehaviorSubject<boolean>(false);
  @Output() onResetPasswordRequired = new BehaviorSubject<boolean>(false);
  @Output() onSetupMfaRequired = new BehaviorSubject<string>(undefined);
  @Output() onConfirmSignInRequired = new BehaviorSubject<boolean>(false);
  @Output() onEnvironment = AuthService.authServerSubject.asObservable().pipe(
    distinctUntilChanged(deepEqual),
    shareReplay(1),
    map((authServer?: AuthServer) => authServer?.environment)
  );
  @Output() onAuthDomain = AuthService.authServerSubject.asObservable().pipe(
    distinctUntilChanged(deepEqual),
    shareReplay(1),
    map((authServer?: AuthServer) => authServer?.domainName)
  );
  private userSessionSubject = new BehaviorSubject<UserSession>(undefined);
  @Output() onUserSession = this.userSessionSubject.asObservable().pipe(
    distinctUntilChanged(deepEqual),
    switchMap((user) => {
      return this.onEnvironment.pipe(
        map((environment) => {
          return user && environment
            ? {
                idToken: user.idToken,
                accessToken: user.accessToken,
                environment: environment,
                isValid: () => user.isValid(),
              }
            : undefined;
        })
      );
    }),
    filter((session) => !!session),
    shareReplay(1)
  );
  private userSubject = new BehaviorSubject<CognitoUser>(undefined);

  constructor(private authenticator: AuthenticatorService) {
    Hub.listen('auth', this.listener);
    AuthService.globalUserSession.subscribe((userSession) => {
      this.userSessionSubject.next(userSession);
      if (isValidSession(userSession)) {
        this.onLoginRequired.next(false);
      }
    });
    AuthService.globalUserSession.pipe(take(1)).subscribe((userSession) => {
      this.onLoginRequired.next(!isValidSession(userSession));
    });
  }

  static configure = async (
    httpClient: HttpClient,
    settingsService: AuthSettingsService
  ) => {
    const redirectBase = window.location.hostname.includes('localhost')
      ? 'http://localhost:4200'
      : 'https://' + window.location.hostname;
    try {
      const settings = await settingsService.loadSettings();
      const authServers = settings.authServers;
      AuthService.authServerSubject.next(undefined);
      AuthService.authServers = authServers;
      AuthService.accessibleAccounts = settings.accessibleAccounts;
      AuthService.administeredAccounts = settings.administeredAccounts;

      for (const authServer of authServers) {
        if (AuthService.authServerSubject.getValue() === undefined) {
          Auth.configure({
            region: 'eu-west-2',
            userPoolId: authServer.userPoolId,
            userPoolWebClientId: authServer.webClientId,
            oauth: {
              scope: ['email', 'profile', 'openid'],
              redirectSignIn: redirectBase + '/auth/token',
              redirectSignOut: redirectBase + '/logout',
              responseType: 'token',
            },
          });
          await Auth.currentSession()
            .then((userSession) => {
              if (userSession.isValid()) {
                AuthService.authServerSubject.next(authServer);
                AuthService.refreshSessionTrigger.next();
              }
            })
            .catch((err) => {
              // fall through to other auth servers if any
            });
        }
      }
    } catch (err) {
      // TODO - this is fatal!!!
      console.log('Failed to load settings for authentication!!!');
      console.dir(err);
    }
  };

  async login(username: string, password: string) {
    const redirectBase = window.location.hostname.includes('localhost')
      ? 'http://localhost:4200'
      : 'https://' + window.location.hostname;
    try {
      AuthService.authServerSubject.next(undefined);
      for (const authServer of AuthService.authServers) {
        if (AuthService.authServerSubject.getValue() === undefined) {
          Auth.configure({
            region: 'eu-west-2',
            userPoolId: authServer.userPoolId,
            userPoolWebClientId: authServer.webClientId,
            oauth: {
              scope: ['email', 'profile', 'openid'],
              redirectSignIn: redirectBase + '/auth/token',
              redirectSignOut: redirectBase + '/logout',
              responseType: 'token',
            },
          });
          await Auth.signIn(username, password)
            .then((user: CognitoUser | any) => {
              AuthService.authServerSubject.next(authServer);
              this.handleResponse(user);
            })
            .catch((err) => {
              if (err.code === 'PasswordResetRequiredException') {
                this.onLoginRequired.next(false);
                this.onResetPasswordRequired.next(true);
              }
              // expected - just fall through to next auth server
            });
        }
      }
      if (AuthService.authServerSubject.getValue() === undefined) {
        this.onAuthError.next({
          code: 'InvalidCredentials',
          name: 'InvalidCredentials',
          message: 'Invalid Username or Password',
        });
      }
    } catch (err) {
      AuthService.authServerSubject.next(undefined);
      this.onAuthError.next({
        code: 'LoginFailed',
        name: 'LoginFailed',
        message: 'Failed to login - please reload',
      });
    }
  }

  saveNewPassword(password: string, name: string) {
    Auth.completeNewPassword(this.userSubject.getValue(), password, {
      name: name,
    })
      .then((user: CognitoUser | any) => {
        this.handleResponse(user);
      })
      .catch((err) => {
        this.onAuthError.next(err);
      });
  }

  async forgotPassword(username: string) {
    const redirectBase = window.location.hostname.includes('localhost')
      ? 'http://localhost:4200'
      : 'https://' + window.location.hostname;
    try {
      AuthService.authServerSubject.next(undefined);
      for (const authServer of AuthService.authServers) {
        Auth.configure({
          region: 'eu-west-2',
          userPoolId: authServer.userPoolId,
          userPoolWebClientId: authServer.webClientId,
          oauth: {
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: redirectBase + '/auth/token',
            redirectSignOut: redirectBase + '/logout',
            responseType: 'token',
          },
        });
        await Auth.forgotPassword(username)
          .then((user: CognitoUser | any) => {
            AuthService.authServerSubject.next(authServer);
            this.onLoginRequired.next(false);
            this.onResetPasswordRequired.next(true);
          })
          .catch((err) => {
            // expected - just fall through to next auth server
          });
      }
      if (AuthService.authServerSubject.getValue() === undefined) {
        this.onAuthError.next({
          code: 'InvalidCredentials',
          name: 'InvalidCredentials',
          message: 'Invalid Username',
        });
      }
    } catch (err) {
      AuthService.authServerSubject.next(undefined);
      this.onAuthError.next({
        code: 'LoginFailed',
        name: 'LoginFailed',
        message: 'Failed to login - please reload',
      });
    }
  }

  async resetPassword(
    username: string,
    password: string,
    verificationCode: string
  ) {
    const redirectBase = window.location.hostname.includes('localhost')
      ? 'http://localhost:4200'
      : 'https://' + window.location.hostname;
    try {
      AuthService.authServerSubject.next(undefined);
      for (const authServer of AuthService.authServers) {
        Auth.configure({
          region: 'eu-west-2',
          userPoolId: authServer.userPoolId,
          userPoolWebClientId: authServer.webClientId,
          oauth: {
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: redirectBase + '/auth/token',
            redirectSignOut: redirectBase + '/logout',
            responseType: 'token',
          },
        });
        await Auth.forgotPasswordSubmit(username, verificationCode, password)
          .then((user: CognitoUser | any) => {
            AuthService.authServerSubject.next(authServer);
            this.onLoginRequired.next(true);
            this.onResetPasswordRequired.next(false);
            this.onAuthInfo.next('Password changed successfully');
            return AuthService.refreshSessionTrigger.next();
          })
          .catch((err) => {
            // FIXME: handle expired code exception: ExpiredCodeException
            // FIXME: handle expired code exception: LimitExceededException
            // this.onAuthError.next(err)
          });
      }
      if (AuthService.authServerSubject.getValue() === undefined) {
        this.onAuthError.next({
          code: 'InvalidCredentials',
          name: 'InvalidCredentials',
          message: 'Invalid Username',
        });
      }
    } catch (err) {
      AuthService.authServerSubject.next(undefined);
      this.onAuthError.next({
        code: 'LoginFailed',
        name: 'LoginFailed',
        message: 'Failed to login - please reload',
      });
    }
  }

  verifySetupMfa(verificationCode: string) {
    const user = this.userSubject.getValue();
    Auth.verifyTotpToken(user, verificationCode)
      .then((userSession) => {
        this.onSetupMfaRequired.next(undefined);
        AuthService.refreshSessionTrigger.next();
      })
      .catch((err) => {
        this.onAuthError.next(err);
      });
  }

  confirmSignIn(verificationCode: string) {
    const user = this.userSubject.getValue();
    Auth.confirmSignIn(user, verificationCode, 'SOFTWARE_TOKEN_MFA')
      .then((res: CognitoUser | any) => {
        this.handleResponse(res);
      })
      .catch((err) => {
        this.onAuthError.next(err);
        return AuthService.refreshSessionTrigger.next();
      });
  }

  signOut() {
    this.onConfirmSignInRequired.next(false);
    this.onSetupMfaRequired.next(undefined);
    this.onNewPasswordRequired.next(false);
    this.onForgotPassword.next(false);
    this.onResetPasswordRequired.next(false);
    this.onLoginRequired.next(false);
    this.userSubject.next(undefined);
    if (AuthService.authServers.length > 1) {
      AuthService.authServerSubject.next(undefined);
    }
    return Auth.signOut()
      .then((res) => {
        this.onLoginRequired.next(true);
        window.location.reload();
        return AuthService.refreshSessionTrigger.next();
      })
      .catch((err) => {
        this.onLoginRequired.next(true);
        this.onAuthError.next(err);
        window.location.reload();
        return AuthService.refreshSessionTrigger.next();
      });
  }

  sessionTimeout() {
    this.onConfirmSignInRequired.next(false);
    this.onSetupMfaRequired.next(undefined);
    this.onNewPasswordRequired.next(false);
    this.onForgotPassword.next(false);
    this.onResetPasswordRequired.next(false);
    this.onLoginRequired.next(false);
    this.userSubject.next(undefined);
    if (AuthService.authServers.length > 1) {
      AuthService.authServerSubject.next(undefined);
    }
    return Auth.signOut()
      .then((res) => {
        this.onLoginRequired.next(true);
        // window.location.reload();
        return AuthService.refreshSessionTrigger.next();
      })
      .catch((err) => {
        this.onLoginRequired.next(true);
        this.onAuthError.next(err);
        // window.location.reload();
        return AuthService.refreshSessionTrigger.next();
      });
  }

  environments = (): Observable<string> => {
    return of(
      ...AuthService.authServers.map((authServer) => authServer.environment)
    );
  };

  accessibleAccounts = (): Observable<string[]> => {
    return of(AuthService.accessibleAccounts);
  };

  administeredAccounts = (): Observable<string[]> => {
    return of(AuthService.administeredAccounts);
  };

  handleResponse = (user: CognitoUser | any) => {
    if (isCognitoUser(user)) {
      this.userSubject.next(user);
      this.onAuthError.next(undefined);
      if (requiresNewPassword(user)) {
        this.onLoginRequired.next(false);
        this.onNewPasswordRequired.next(true);
      } else if (requiresMfaSetup(user)) {
        Auth.setupTOTP(user)
          .then((code) => {
            this.onLoginRequired.next(false);
            this.onNewPasswordRequired.next(false);
            const environment = AuthService.authServerSubject.getValue()
              .environment;
            const suffix =
              environment === 'Production' || environment === 'Client'
                ? ''
                : '(' + environment + ')';
            this.onSetupMfaRequired.next(
              'otpauth://totp/' +
                user.getUsername() +
                '?secret=' +
                code +
                '&issuer=inSPireDirect' +
                suffix
            );
          })
          .catch((err) => {
            this.onAuthError.next(err);
          });
      } else if (requiresTotpToken(user)) {
        this.onLoginRequired.next(false);
        this.onNewPasswordRequired.next(false);
        this.onSetupMfaRequired.next(undefined);
        this.onConfirmSignInRequired.next(true);
      } else {
        AuthService.refreshSessionTrigger.next();
      }
    } else {
      this.onConfirmSignInRequired.next(false);
      this.onSetupMfaRequired.next(undefined);
      this.onNewPasswordRequired.next(false);
      this.onLoginRequired.next(true);
      this.userSubject.next(undefined);
      // TODO - Clearly this could be serious?
      console.log('Unexpected Auth Response');
      console.dir(user);
    }
  };
  listener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        Auth.currentAuthenticatedUser().then(() => {
          window.location.reload();
        });
        break;
    }
  };
}

const isCognitoUser = (user: CognitoUser | any): user is CognitoUser => {
  return (user as CognitoUser).getUsername !== undefined;
};

const requiresMfaSetup = (user: CognitoUser) => {
  return user['challengeName'] === 'MFA_SETUP';
};

const requiresTotpToken = (user: CognitoUser) => {
  return user['challengeName'] === 'SOFTWARE_TOKEN_MFA';
};

const requiresNewPassword = (user: CognitoUser) => {
  return user['challengeName'] === 'NEW_PASSWORD_REQUIRED';
};

const isValidSession = (session: UserSession) => {
  return session && session.isValid();
};
