import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dc-company-request-card',
  templateUrl: './dc-company-request-card.component.html',
  styleUrls: ['./dc-company-request-card.component.scss'],
})
export class DcCompanyRequestCardComponent implements OnInit {
  @Input() companyName: string;
  @Input() companyContact: string;
  @Output() companyAndContactDataDecline = new EventEmitter<{ company: string; contact: string }>();
  @Output() companyAndContactDataAccept = new EventEmitter<{ company: string; contact: string }>();

  constructor() {
  }

  ngOnInit(): void {
  }

  getInfoDecline(name: string, contact: string) {
    this.companyAndContactDataDecline.emit({company: name, contact: contact});
  }

  getInfoAccept(name: string, contact: string) {
    this.companyAndContactDataAccept.emit({company: name, contact: contact});
  }
}
