import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MonacoEditorService } from '../dc-monaco-standalone/monaco-editor.service';
import { first } from 'rxjs/operators';
declare let monaco: any;
@Component({
  selector: 'app-dc-monaco-diff',
  templateUrl: './dc-monaco-diff.component.html',
  styleUrls: ['./dc-monaco-diff.component.scss'],
})
export class DcMonacoDiffComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('editorContainer', { static: true }) _editorContainer: ElementRef;
  _originalModel: DiffEditorModel;
  _modifiedModel: DiffEditorModel;
  _editor;
  editorOptions = {
    theme: 'vs-dark',
    autoIndent: true,
    formatOnPaste: true,
    formatOnType: true,
    automaticLayout: true,
  };
  diffCounter = 0;
  protected _options: any;
  @Input('options')
  set options(options: any) {
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.editorOptions);
    }
  }

  get options(): any {
    return this._options;
  }

  @Input('originalModel')
  set originalModel(model: DiffEditorModel) {
    this._originalModel = model;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.editorOptions);
    }
  }

  @Input('modifiedModel')
  set modifiedModel(model: DiffEditorModel) {
    this._modifiedModel = model;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.editorOptions);
    }
  }

  @Output() monacoInit = new EventEmitter();
  @Output() monacoDiff = new EventEmitter();
  constructor(
    private monacoEditorService: MonacoEditorService,
    private injector: Injector,
    private zone: NgZone
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this._editor) {
      this._editor.dispose();
      this._editor = undefined;
      this.initMonaco(this.editorOptions);
    }
  }

  protected initMonaco(options: any): void {
    if (!this.monacoEditorService.loaded) {
      this.monacoEditorService.loadingFinished.pipe(first()).subscribe(() => {
        this.initMonaco(this.editorOptions);
      });
      return;
    }

    if (!this._originalModel || !this._modifiedModel) {
      throw new Error(
        'originalModel or modifiedModel not found for ngx-monaco-diff-editor'
      );
    }

    this._originalModel.language =
      this._originalModel.language || options.language;
    this._modifiedModel.language =
      this._modifiedModel.language || options.language;

    let originalModel = monaco.editor.createModel(
      this._originalModel.code,
      this._originalModel.language
    );
    let modifiedModel = monaco.editor.createModel(
      this._modifiedModel.code,
      this._modifiedModel.language
    );

    this._editorContainer.nativeElement.innerHTML = '';
    const theme = options.theme;
    this._editor = monaco.editor.createDiffEditor(
      this._editorContainer.nativeElement,
      options
    );
    options.theme = theme;
    this._editor.setModel({
      original: originalModel,
      modified: modifiedModel,
    });

    this._editor.onDidUpdateDiff((x) => {
      this.diffCounter++;
      if (this.diffCounter == 2) {
        this.monacoDiff.emit();
      }
    });
    this.monacoInit.emit(this._editor);
  }

  ngAfterViewInit(): void {
    this.initMonaco(this.editorOptions);
  }

  ngOnDestroy() {
    if (this._editor) {
      this._editor.dispose();
      this._editor = undefined;
    }
  }
}
export interface DiffEditorModel {
  code: string;
  language: string;
}
