<!-- render inside in a container div with correct sizing and padding -->
<div class="progressBarText">
  <p>{{ progressLabel ? progressLabel : 'Completed' }}</p>
  <p>{{ progressValue }}%</p>
</div>
<div class="progress" style="height: 6px">
  <div
    [attr.aria-valuenow]="progressValue"
    [class.almost-progress]="progressValue < 100"
    [class.done-progress]="progressValue === 100"
    [class.empty-progress]="progressValue === 0"
    [class.partial-progress]="progressValue < 50"
    [style.width.%]="progressValue"
    aria-valuemax="100"
    aria-valuemin="0"
    class="progress-bar"
    role="progressbar"
  ></div>
</div>
