<div class="position-relative">
  <input
    [formControl]="control"
    [class]="
      'form-control input searchbar' + ' ' + (darkMode === true ? 'dark' : '')
    "
    [placeholder]="placeholder"
    #searchInput
  />
  <span
    (click)="searchInput.value = ''; handleClear()"
    *ngIf="searchInput.value.length > 0"
    class="handleClearBtn"
    title="Clear search filter"
    >×</span
  >
</div>
