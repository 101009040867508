import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dc-popover',
  templateUrl: './dc-popover.component.html',
  styleUrls: ['./dc-popover.component.scss'],
})
export class DcPopoverComponent implements OnInit {
  @Input() label: any;
  @Input() body: any;

  constructor() {}

  ngOnInit(): void {}
}
