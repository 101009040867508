import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DcModalComponent} from './dc-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private ngbModal: NgbModal) {
  }

  openDialog(props: any): Promise<any> {
    // console.log("hello from service")
    const modalRef = this.ngbModal.open(DcModalComponent, {
      size: props.size,
      backdrop: props.backdrop,
      centered: true,
    });
    modalRef.componentInstance.setDialogProps(props);
    return modalRef.result;
  }
}
