<div>
  <label *ngIf="label" [for]="id">{{ label }}</label>

  <div [class]="'input-group' + ' ' + (darkMode === true ? 'dark' : '')">
    <div class="input-group-prepend">
      <button class="btn btn-link calendar" (click)="canClickDateButton(datepicker)" type="button">
        <img src="/assets/calendar.svg" alt="Date range" />
      </button>
    </div>

    <input
      #myRangeInput
      name="datepicker"
      class="form-control"
      ngbDatepicker
      #datepicker="ngbDatepicker"
      (dateSelect)="onDateSelection($event, datepicker)"
      [displayMonths]="2"
      [dayTemplate]="t"
      [placeholder]="placeholder"
      [id]="id"
      readonly="true"
      [outsideDays]="'hidden'"
      [autoClose]="'true'"
    />

    <ng-template #t let-date="date" let-focused="focused">
      <span
        class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </span>
    </ng-template>
    <div class="clearDate" *ngIf="showDateClear" (click)="clearDate()" title="Clear date range">×</div>
  </div>
</div>
