import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { AdminGuard } from './shared/guards/admin-guard.service';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AdminGuard],
    canActivate: [AdminGuard],
  },
  {
    path: 'validation',
    loadChildren: () =>
      import('./validation/validation.module').then((m) => m.ValidationModule),
  },
  {
    path: 'regression',
    loadChildren: () =>
      import('./regression/regression.module').then((m) => m.RegressionModule),
  },
  {
    path: 'rules',
    loadChildren: () =>
      import('./rules/rules.module').then((m) => m.RulesModule),
  },

  { path: '', redirectTo: 'validation', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent, data: { title: '404' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
