import { Component, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../controlvalue-accessor.connector';

@Component({
  selector: 'app-dc-textarea',
  templateUrl: './dc-textarea.component.html',
  styleUrls: ['./dc-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DcTextareaComponent,
      multi: true,
    },
  ],
})
export class DcTextareaComponent extends ControlValueAccessorConnector {
  @Input() label: string;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() class: string;
  constructor(injector: Injector) {
    super(injector);
  }
}
