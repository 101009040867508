import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AdminGuard } from '../../../shared/guards/admin-guard.service';
import {
  ActivationEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';

@Component({
  selector: 'app-dc-navbar',
  templateUrl: './dc-navbar.component.html',
  styleUrls: ['./dc-navbar.component.scss'],
})
export class DcNavbarComponent implements OnInit {
  @Input() user: any;
  @Input() email: any;
  @Input() loggedInAsDistributor: boolean;
  @Input() navigating: boolean;
  @Output() signOutEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('userMenu') userMenu: ElementRef;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  public activeRoute: string;
  public userPanelCollapsed;
  public navbarMenuCollapsed;

  constructor(
    private router: Router,
    public adminGuard: AdminGuard,
    private renderer: Renderer2
  ) {
    router.events.subscribe((event: any) => {
      this.navigationInterceptor(event);
    });
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        !this.userMenu.nativeElement.contains(e.target) &&
        !this.toggleButton.nativeElement.contains(e.target)
      ) {
        this.userPanelCollapsed = true;
      }
    });
  }

  ngOnInit(): void {
    this.navbarMenuCollapsed = true;
    this.userPanelCollapsed = true;
  }

  navbarMenuButton() {
    this.navbarMenuCollapsed = !this.navbarMenuCollapsed;
    this.userPanelCollapsed = true;
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.navigating = true;
      this.activeRoute = undefined;
      this.navbarMenuCollapsed = true;
      this.userPanelCollapsed = true;
    }
    if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      this.navigating = false;
      this.navbarMenuCollapsed = true;
      this.userPanelCollapsed = true;
      this.activeRoute = event.url;
    }
    if (event instanceof ActivationEnd) {
      this.navbarMenuCollapsed = true;
      this.userPanelCollapsed = true;
      this.activeRoute = event.snapshot.routeConfig.path;
    }
  }

  signOut() {
    this.signOutEmitter.emit();
  }
}
