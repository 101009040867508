import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginScreenComponent } from './login-screen/login-screen.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { AuthSettingsService } from './services/auth-settings.service';
import { AuthService } from './services/auth.service';
import { LoginFormComponent } from './login-screen/login-form/login-form.component';
import { SetupMfaFormComponent } from './login-screen/setup-mfa-form/setup-mfa-form.component';
import { NewPasswordFormComponent } from './login-screen/new-password-form/new-password-form.component';
import { ConfirmSignInFormComponent } from './login-screen/confirm-signin-form/confirm-sign-in-form.component';
import { ResetPasswordFormComponent } from './login-screen/reset-password-form/reset-password-form.component';

export const configureAuth = (
  httpClient: HttpClient,
  settingsService: AuthSettingsService
) => async () => await AuthService.configure(httpClient, settingsService);

@NgModule({
  declarations: [
    LoginScreenComponent,
    LoginFormComponent,
    NewPasswordFormComponent,
    SetupMfaFormComponent,
    ConfirmSignInFormComponent,
    ResetPasswordFormComponent,
  ],
  imports: [
    CommonModule,

    HttpClientModule,

    FormsModule,
    ReactiveFormsModule,

    FontAwesomeModule,
    NgbModule,

    QRCodeModule,

    LoggerModule.forRoot({
      enableSourceMaps: true,
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
  ],
  providers: [
    AuthSettingsService,
    {
      provide: APP_INITIALIZER,
      deps: [HttpClient, AuthSettingsService],
      useFactory: configureAuth,
      multi: true,
    },
    AuthService,
  ],
  bootstrap: [],
  exports: [LoginScreenComponent],
})
export class AuthModule {}
