<a *ngIf="externalLink" [href]="linkRoute" target="_blank">
  <button
    [disabled]="disabled"
    [ngbPopover]="popContent"
    class="linkButton"
    container="body"
    triggers="{{ disabledPopoverText && disabled === true ? 'mouseenter:mouseleave' : null }}"
  >
    <h5 class="font-weight-300">{{ buttonText }}</h5>
    <img alt="arrow icon" src="/assets/next-chevron.svg"/>
  </button>
</a>
<div
  [ngbPopover]="popContent"
  container="body"
  triggers="{{ disabledPopoverText && disabled === true ? 'mouseenter:mouseleave' : null }}"
>
  <button *ngIf="!externalLink" [disabled]="disabled" [routerLink]="linkRoute" class="linkButton">
    <h5 class="font-weight-300">{{ buttonText }}</h5>
    <img alt="arrow icon" src="/assets/next-chevron.svg"/>
  </button>
</div>
<ng-template #popContent>
  <div [innerHtml]="disabledPopoverText"></div>
</ng-template>
