import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlValueAccessorConnector } from '../controlvalue-accessor.connector';

@Component({
  selector: 'app-dc-select',
  templateUrl: './dc-select.component.html',
  styleUrls: ['./dc-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DcSelectComponent,
      multi: true,
    },
  ],
})
export class DcSelectComponent extends ControlValueAccessorConnector {
  @Input() label: string;
  @Input() labelId: string;
  @Input() multiple: boolean;
  @Input() searchable: boolean;
  @Input() closeOnSelect: boolean;
  @Input() items: any[];
  @Input() readonly: boolean;
  @Input() checkbox: boolean;
  @Input() placeholder: string;
  @Input() darkMode?: boolean;
  @Input() appendTo?: string;
  @Input() loading?: boolean;
  @Input() validation?: boolean;
  @Input() compareWith?: boolean;
  @Input() clearable?: boolean;
  @Output() disableEvent = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>();

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {}

  onChange(e) {
    //  console.log(e);
    if (e !== undefined) {
      this.selected.emit({ id: e.id, displayValue: e.displayValue });
    } else {
      this.selected.emit(null);
    }
  }
  compareFn(item, selected) {
    const returnValue =
      typeof selected === 'string'
        ? true
        : typeof selected === 'number'
        ? item.id === selected
        : item.displayValue === selected.name;
    return returnValue;
  }
}
