<div class="outerLoadingContainer">
  <div class="loadingContainer hasText">
    <div class="spinner">
      <div class="marker"></div>
      <div class="marker" style="animation-delay: 0.05s"></div>
      <div class="marker" style="animation-delay: 0.1s"></div>
    </div>
    <div class="loadingText">
      <span>{{ loadingText ? loadingText : ' ' }}</span>
      <!-- <ng-content select=".loadingText"></ng-content> -->
    </div>
  </div>
</div>
