import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dc-control-validation',
  templateUrl: './dc-control-validation.component.html',
  styleUrls: ['./dc-control-validation.component.scss'],
})
export class DcControlValidationComponent implements OnInit {
  @Input() label: any;
  @Input() body: any;
  @Input() status: any;
  @Input() class: any;
  constructor() {}

  ngOnInit(): void {}
}
