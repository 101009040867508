<div class="requestCard d-flex">
  <div class="requestNameDetails text-truncate">
    <h4 class="font-weight-400 text-truncate">
      {{ companyName }}
    </h4>
    <p class="margin-0 text-truncate">
      {{ companyContact }}
    </p>
  </div>
  <div class="requestButtons d-flex flex-nowrap justify-content-end">
    <button
      (click)="getInfoDecline(companyName, companyContact)"
      class="btn btn-darkerGrey badge bg-darkerGrey font-weight-500"
      title="Decline request"
    >
      DECLINE
    </button>
    <button
      (click)="getInfoAccept(companyName, companyContact)"
      class="btn btn-success badge bg-success font-weight-500"
      title="Accept request"
    >
      ACCEPT
    </button>
  </div>
</div>
