import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { AuthService } from './auth/services/auth.service';
import { DateTime } from 'luxon';
//import { combineLatest } from 'rxjs';
import { CurrentUserService } from './shared/services/current-user.service';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loading = false;
  navigating = false;
  loggedInAsDistributor = false;
  signedIn = false;
  user: string = undefined;
  email: string = undefined;
  closePanels = false;

  appVersion = 'APP_VERSION_PLACEHOLDER';
  appBuildNumber = 'APP_BUILD_NUMBER_PLACEHOLDER';
  appBuildTime = parseInt('APP_BUILD_TIME_PLACEHOLDER', 10) || 1669994985307;
  environment = '';
  appBuildDateString: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    public authenticator: AuthenticatorService
  ) {
    try {
      if (this.appVersion.startsWith('APP_')) {
        this.appVersion = 'DEV-BUILD-0.1.734';
      }
      if (this.appBuildNumber.startsWith('APP_')) {
        this.appBuildNumber = 'DEV';
      }
      const dateTime = DateTime.fromMillis(this.appBuildTime);
      this.appBuildDateString = dateTime.toLocaleString({
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });
      // this.authService.oauthToken.subscribe(token => this.token = token)
    } catch (e) {
      console.error('Failed to determine application version', e);
    }
  }

  ngOnInit() {
    // combineLatest([
    //   this.authService.onUserSession,
    //   this.currentUserService.currentUser,
    // ]).subscribe(([session, currentUser]) => {
    //   // if (currentUser.type === 'distributor') {
    //   //   this.loggedInAsDistributor = true;
    //   // }
    //   this.loading = false;
    //   this.signedIn = session?.isValid();
    //   this.user =
    //     session?.isValid() && session.idToken.payload['cognito:username'];
    //   this.email = session?.isValid() && session.idToken.payload.email;
    // });
    this.authService.onEnvironment.subscribe((environment) => {
      this.environment = environment;
    });
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl + ' - InSPire Direct');
      });
  }

  ngOnDestroy(): void {}

  // async signOut() {
  //   this.currentUserService.currentUser.subscribe(async (currentUser) => {
  //     await this.authService.signOut();
  //   });
  // }
}
