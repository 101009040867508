<div class="form-check {{ class }}">
  <input
    (change)="changed.emit($event)"
    [formControl]="control"
    [id]="id"
    class="form-check-input"
    type="checkbox"
  />
  <label [for]="id" class="form-check-label">{{ label }}</label>
</div>
