<nav class="navbar navbar-expand-sm navbar-dark bg-dark">
  <button
    (click)="navbarMenuButton()"
    [class.open]="!navbarMenuCollapsed"
    aria-controls="navbarOptions"
    aria-expanded="false"
    aria-label="Toggle navigation"
    class="navbar-toggler border-0 ps-0 pe-3"
    data-target="#navbarOptions"
    data-toggle="collapse"
    id="navBar"
    type="button"
  >
    <span class="line"></span>
    <span class="line"></span>
    <span class="line"></span>
  </button>
  <div class="navbar-brand logo navbar-brand logo"></div>
  <div
    [animation]="false"
    [ngbCollapse]="navbarMenuCollapsed"
    class="collapse navbar-collapse"
    id="navbarOptions"
  >
    <ul
      [(activeId)]="activeRoute"
      class="navbar-nav mr-auto flex-nowrap"
      ngbNav
      #nav="ngbNav"
    >
      <!-- <li
        *ngIf="adminGuard.hasAccess && !loggedInAsDistributor"
        class="nav-item"
        ngbNavItem="admin"
      >
        <a class="nav-link" ngbNavLink routerLink="admin">Admin</a>
      </li> -->
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="validation"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
          >Validation</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="regression"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
          >Regression</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="rules"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
          >Rules</a
        >
      </li>
    </ul>
  </div>
  <div
    #toggleButton
    (click)="
      userPanelCollapsed = !userPanelCollapsed; navbarMenuCollapsed = true
    "
    [attr.aria-expanded]="!userPanelCollapsed"
    aria-controls="userPanelCollapsed"
    aria-expanded="false"
    aria-label="Toggle navigation"
    class="userContainer d-flex cursor-pointer"
    data-target="#userPanelCollapsed"
    data-toggle="collapse"
    id="userPanel"
  >
    <div class="userName text-large d-none d-sm-block me-1">{{ user }}</div>
    <div class="ms-2 userIcon"></div>
  </div>
  <div
    #userMenu
    [animation]="false"
    [ngbCollapse]="userPanelCollapsed"
    class="userPanel"
  >
    <div class="p-3 border-bottom border-faded12">
      <div class="userContainer d-flex">
        <div class="userIcon"></div>
        <div class="userInfo">
          <div class="userName text-large text-truncate text-white ps-2">
            {{ user }}
          </div>
          <div class="userEmail text-small text-truncate ps-2">
            {{ email }}
          </div>
        </div>
      </div>
    </div>
    <div class="section p-3">
      <button (click)="signOut()" class="btn btn-link text-white p-0">
        Sign Out
      </button>
    </div>
  </div>
</nav>
