import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './views/dashboard.component';
import { AuthModule } from './auth/auth.module';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { UiKitModule } from '@o3/ui-kit';
import { declarations } from './core';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthSettingsService } from './auth/services/auth-settings.service';
import { AuthService } from './auth/services/auth.service';

export const configureAuth = (
  httpClient: HttpClient,
  settingsService: AuthSettingsService
) => async () => await AuthService.configure(httpClient, settingsService);
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    PageNotFoundComponent,
    declarations,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    NgbModule,
    UiKitModule,
    ScrollingModule,
    AuthModule,
    AmplifyAuthenticatorModule,
    LoggerModule.forRoot({
      enableSourceMaps: true,
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
  ],
  providers: [
    AuthSettingsService,
    {
      provide: APP_INITIALIZER,
      deps: [HttpClient, AuthSettingsService],
      useFactory: configureAuth,
      multi: true,
    },
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
