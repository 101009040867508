import { Component, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../controlvalue-accessor.connector';

@Component({
  selector: 'app-dc-input',
  templateUrl: './dc-input.component.html',
  styleUrls: ['./dc-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DcInputComponent,
      multi: true,
    },
  ],
})
export class DcInputComponent extends ControlValueAccessorConnector {
  @Input() readonly: boolean;
  @Input() type: 'text' | 'number' | 'email' | 'password';
  @Input() placeholder: string;
  @Input() label: string;
  @Input() darkMode?: boolean;
  @Input() field: any;
  @Input() id: any;
  @Input() validation?: boolean;
  constructor(injector: Injector) {
    super(injector);
  }
}
