import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dc-modal',
  templateUrl: './dc-modal.component.html',
  styleUrls: ['./dc-modal.component.scss'],
})
export class DcModalComponent implements OnInit {
  public title = null;
  public body = null;
  public action = null;
  public positive = null;
  public negative = null;
  public link = null;
  public close = null;
  actionLink = null;
  actionWindow = null;

  constructor(public modal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  setDialogProps(props: any) {
    this.title = props.title || '';
    this.body = props.body || '';
    this.action = props.buttons.action || null;
    this.positive = props.buttons.positive || null;
    this.negative = props.buttons.negative || null;
    this.close = props.buttons.close || null;
    this.actionLink = props.buttons.actionLink || null;
    this.actionWindow = props.buttons.actionWindow || null;
  }

  onAction(action: any) {
    if (action.action == 1) {
      if (this.actionLink !== null) {
        window.open(this.actionLink, this.actionWindow);
      }
    } else {
      this.modal.close(JSON.stringify(action));
    }
  }
}
