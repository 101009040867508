import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dc-nav-link',
  templateUrl: './dc-nav-link.component.html',
  styleUrls: ['./dc-nav-link.component.scss'],
})
export class DcNavLinkComponent implements OnInit {
  @Input() externalLink: boolean;
  @Input() linkRoute: string;
  @Input() buttonText: string;
  @Input() disabled: boolean;
  @Input() disabledPopoverText: string;

  constructor() {
  }

  ngOnInit(): void {
  }
}
