import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';

@Component({
  selector: 'app-dc-download-csv',
  templateUrl: './dc-download-csv.component.html',
  styleUrls: ['./dc-download-csv.component.scss'],
})
export class DcDownloadCsvComponent implements OnInit {
  @Input() fields: string[]; // fields displayed as columns in the csv
  @Input() csvData: any; // if fields exists, data has to be a corresponding array of arrays ex: https://www.papaparse.com/docs#unparse-config-default  (unparse examples)
  @Input() fileTitle: string;
  @Input() noText: boolean;
  @Input() disabled: boolean;
  @Input() title: string = 'CSV Download';
  @Output() downloadCsvEmitter = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  downloadCSV(csvData?: any): void {
    const data = csvData ? csvData : this.csvData;
    const fields = this.fields;
    //TODO: TOASTER MESSAGE IF NO DATA TO DOWNLOAD MAYBE??
    if (data) {
      const csv = fields ? unparse({ fields, data }) : unparse(data);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      saveAs(
        blob,
        this.fileTitle + new Date(Date.now()).toISOString().split('.')[0]
      );
    }
  }

  emitCsvDownloadRequest(): void {
    this.downloadCsvEmitter.emit(true);
  }
}
