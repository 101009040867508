<button
  #p="ngbPopover"
  (click)="p.open()"
  [ngbPopover]="popContent"
  [popoverTitle]="label ? label : null"
  [title]="label ? label : ''"
  class="btn btn-link popOver"
  container="body"
  popoverClass="dcPopover"
  triggers="mouseenter:mouseleave:manual"
  type="button"
></button>
<ng-template #popContent>
  <div [innerHtml]="body"></div>
</ng-template>
