<h2 class="name">
  <span *ngIf="name" class="name">{{ name }}</span>
</h2>
<div class="companyContactsContainer d-flex flex-column flex-md-row">
  <div *ngIf="address" class="contactsInnerContainer leftContainer w-50">
    <p class="address location-icon icon">
      <span class="address">{{ address }}</span>
    </p>
  </div>
  <div *ngIf="email" class="contactsInnerContainer w-50">
    <p class="webaddress internet-icon icon icon">
      <span class="webaddress">{{ email }}</span>
    </p>
  </div>
</div>
