<label *ngIf="label" [for]="labelId">{{ label }}</label
><ng-select
  [items]="items"
  [addTag]="addTagFn"
  [hideSelected]="false"
  multiple="true"
  bindLabel="name"
  [formControl]="control"
  [loading]="loading"
  [labelForId]="labelId"
>
</ng-select>
