import { Component, OnInit, ViewChild } from '@angular/core';
import { DcAlertService } from './dc-alert.service';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-dc-alert',
  templateUrl: './dc-alert.component.html',
  styleUrls: ['./dc-alert.component.scss'],
})
export class DcAlertComponent implements OnInit {
  alertText = null;
  staticAlertClosed = false;

  @ViewChild('staticAlert', { static: false }) staticAlert?: NgbAlert;
  constructor(private dcAlertService: DcAlertService) {}

  ngOnInit(): void {
    this.dcAlertService.alertType$.subscribe(
      (message) => (this.alertText = message)
    );
    this.dcAlertService.alertType$.pipe(debounceTime(5000)).subscribe(() => {
      if (this.staticAlert) {
        if (!this.alertText?.noAutoClose) {
          this.staticAlert.close();
        }
      }
    });
  }

  close() {
    this.staticAlert.close();
  }
}
