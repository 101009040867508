<div class="form-group">
  <label *ngIf="label" [for]="id">{{label}}</label>
  <div class="input-group">
    <div class="input-group-prepend">
      <button (click)="canClickDateButton(d)" class="btn btn-link calendar" type="button">
        <img alt="Date range" src="/assets/calendar.svg"/>
      </button>
    </div>
    <input
      #d="ngbDatepicker"
      [formControl]="control"
      [id]="id"
      class="form-control"
      container="body"
      name="dp"
      ngbDatepicker
      readonly="true"
    />
  </div>
</div>
