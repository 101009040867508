<div
  class="dlCsvContainer"
  (click)="downloadCSV(); emitCsvDownloadRequest()"
  title="{{ title }}"
>
  <button type="button" class="btn btn-link downloadText" [disabled]="disabled">
    <span *ngIf="!noText">Download CSV</span>
    <i *ngIf="!disabled" class="downloadIcon {{ noText ? 'noText' : '' }}"></i>
    <i
      *ngIf="disabled"
      class="downloadIcon loading {{ noText ? 'noText' : '' }}"
    ></i>
  </button>
</div>
