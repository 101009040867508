<button
  *ngIf="status == 'invalid'"
  #p="ngbPopover"
  (click)="p.open()"
  [ngbPopover]="popContent"
  [popoverTitle]="label ? label : null"
  [title]="label ? label : ''"
  class="popOverValidation invalid {{ class }}"
  container="body"
  popoverClass="dcPopover"
  triggers="mouseenter:mouseleave:manual"
  type="button"
></button>

<span *ngIf="status == 'valid'" class="popOverValidation valid"></span>

<ng-template #popContent>
  <div [innerHtml]="body"></div>
</ng-template>
