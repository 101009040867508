import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dc-company-info-header',
  templateUrl: './dc-company-info-header.component.html',
  styleUrls: ['./dc-company-info-header.component.scss'],
})
export class DcCompanyInfoHeaderComponent implements OnInit {
  @Input() name: string;
  @Input() address: string;
  @Input() email: string;

  constructor() {
  }

  ngOnInit(): void {
  }
}
