<div class="custom-control custom-radio">
  <input
    (change)="changed.emit($event)"
    [formControl]="control"
    [id]="id"
    [name]="name"
    [value]="label"
    class="custom-control-input"
    type="radio"
  />
  <label [for]="id" class="form-check-label custom-control-label">{{ label }}</label>
</div>
